import React, { Component } from 'react';
import ObfuscateText from "react-obfuscate-text";

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var profilepic= "images/"+this.props.data.image;
      var bio = this.props.data.bio;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src={profilepic} alt="Profile Pic" />
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>My name is Gonzalo Rodriguez Mir and Mobotsoft is my professional website.<br/>
            I have a degree in Electronics Engineering from the <a href="http://www.unlp.edu.ar/" target="_blank" rel="noopener noreferrer">National University of La Plata</a> and I have been working as an independent software engineering consultant for more than 25 years.<br/>
            In recent years I have specialized in projects related to robotics and precision agriculture.<br/>
            Please feel free to contact me if you are interested in working together or have any questions.</p>
            <div className="row">
               <div className="columns contact-details">
                  <h2>Contact Details</h2>
                  <p className="address">
						   <span>Gonzalo Rodriguez Mir</span><br />
						   <span>Av. 32 #2882<br />
						         La Plata, Provincia de Buenos Aires, B1903AJQ
                     </span><br />
						   <span>Argentina</span><br />
                     <span><ObfuscateText text="grm@mobotsoft.com"/></span>
					   </p>
               </div>
               {/*
               <div className="columns download">
                  <p>
                     <a href={resumeDownload} className="button"><i className="fa fa-download"></i>Download Resume</a>
                  </p>
               </div>
               */}
            </div>
         </div>
      </div>

   </section>
    );
  }
}

export default About;
