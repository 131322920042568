import React, { Component } from 'react';
import ReactModal from 'react-modal';
import CloudfarmingArticle from './CloudfarmingArticle'; 

ReactModal.setAppElement('#root');

class Cloudfarming extends Component {

   constructor() {
      super();
      this.state = {
         showModal: false
      };

      this.customStyles = {
         overlay: {
            backgroundColor: 'rgba(50, 50, 50, 0.85)',
            zIndex: 1000
         },
         content: {
           position: 'absolute',
           top: '5%',
           left: '5%',
           right: '5%',
           bottom: '5%',
           background: '#eee',
           overflow: 'auto',
           WebkitOverflowScrolling: 'touch',
           borderRadius: '10px',
           outline: 'none',
           padding: '0px'
         }
      };

   }

   handleOpenModal = () => {
      this.setState({ showModal: true });
   }

   handleCloseModal = () => {
      this.setState({ showModal: false });
   }

   render() {

      if (this.props.data) {
         var cloudfarming = this.props.data.cloudfarming.map(function (cloudfarming) {
            return <li key={cloudfarming.user}>
               <blockquote>
                  <p>{cloudfarming.text}</p>
                  <cite>{cloudfarming.user}</cite>
               </blockquote>
            </li>
         })
      }

      return (
         <section id="cloudfarming">
            <div className="text-container">
               <div className="row">

                  <div className="ten columns flex-container">

                     <blockquote>
                        <h1>CloudFarming</h1>
                        <p>
                           CloudFarming is a dynamic mission planner for agricultural robots or autonomous tractors. The system is based on a cloud computing platform and a modern web application as front-end.
                        </p>
                        <p>
                           <img src="/userguide/images/cloudfarming.png"></img>
                        </p>
                        <p>
                           Features:
                        </p>
                        <p>
                            <ul>
                                <li>Route Planner with options for 'Orchard' and 'Open Field'</li>
                                <li>Generation of routes from imported orchard rows, changing parameters such as turning radius and row sequence pattern (straight alternation, skip and fill)</li>
                                <li>Calculation and display of statistics of planned routes (covered, overlap and uncovered areas, traveled and worked distance and time values, etc.)</li>
                                <li>Animation of the trajectory with a 3D model of the vehicle for easy visual tracking of the planned routes</li>
                                <li>Optimal division of complex shape fields into simpler subfields according to the working angle of each subfield</li>
                                <li>Connection to a ROS (Robot Operating System) network. Publication of messages with calculated routes and real-time display of sensors</li>
                                <li>Recording of routes in real time with the coordinates received from a GPS device. Simplification and smoothing of the curves obtained</li>
                                <li>Possibility to geometrically intersect recorded or imported routes with a user-defined working area to cut off turns and obtain orchard rows</li>
                                <li>Import and export of routes and fields in various formats</li>
                            </ul>
                        </p>
                        <p>
                            The videos on this page illustrate how CloudFarming works. For more details on using the latest version of the application, see the <a href="/userguide/index.html" target="_blank" rel="noopener noreferrer">CloudFarming User Guide</a>
                        </p>
                     </blockquote>
                     <div>
                        <button onClick={this.handleOpenModal} className="submit">Continue Reading...</button>
                        <ReactModal
                           onRequestClose={() => this.setState({ showModal: !this.state.showModal })}
                           isOpen={this.state.showModal}
                           style={this.customStyles}
                           shouldCloseOnOverlayClick
                           shouldCloseOnEsc
                        >
                           <button onClick={this.handleCloseModal} style={{padding: "10px", background:"#eee", color: "#000", margin:"0px"}} >X</button>
                           <CloudfarmingArticle/>
                        </ReactModal>
                     </div>

                  </div>
               </div>
            </div>
         </section>
      );
   }
}

export default Cloudfarming;

