import React, { Component } from 'react';
import Reaptcha from 'reaptcha';
import ObfuscateText from "react-obfuscate-text";
import emailjs from 'emailjs-com';

class Contact extends Component {

   constructor(props) {
      super(props);
      this.state = {
         isLoading: false,
         showSuccess: false,
         showError: false,
         contactEmail: "",
         contactName: "",
         contactSubject: "",
         contactMessage: "",
         newUser: null,
         verified: false
      };
   }

   onVerify = recaptchaResponse => {
      this.setState({
         verified: true
      });
   };

   validateForm() {
      return (
         this.state.contactEmail.length > 0 &&
         this.state.contactName.length > 0 &&
         this.state.contactMessage.length > 0
      );
   }

   handleChange = event => {
      this.setState({
         [event.target.id]: event.target.value
      });
   }

   handleSubmit = e => {

      e.preventDefault();

      const templateParams = {
         from_name: this.state.contactName,
         from_email: this.state.contactEmail,
         //to_name: '/*YOUR NAME OR COMPANY*/',
         subject: this.state.contactSubject,
         message: this.state.contactMessage
      };

      this.setState({ isLoading: true });

      emailjs.send('service_2k9e6jk', 'template_7z3tobp', templateParams, 'user_HwTvb2aFsinMvnyDUro4W')
         .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            this.setState({ showSuccess: true });
            this.setState({ isLoading: false });
         }, (err) => {
            console.log('FAILED...', err);
            this.setState({ showError: true });;
            this.setState({ isLoading: false });
         });

   }

   render() {

      if (this.props.data) {
         var name = this.props.data.name;
         var street = this.props.data.address.street;
         var city = this.props.data.address.city;
         var state = this.props.data.address.state;
         var zip = this.props.data.address.zip;
         var phone = this.props.data.phone;
         var email = this.props.data.email;
         var message = this.props.data.contactmessage;
      }

      return (
         <section id="contact">

            <div className="row section-head">

               <div className="two columns header-col">

                  <h1><span>Get In Touch.</span></h1>

               </div>

               <div className="ten columns">

                  <p className="lead">Get In Touch</p>

               </div>

            </div>

            <div className="row">
               <div className="eight columns">

                  <form id="contactForm" >
                     <fieldset>

                        <div>
                           <label htmlFor="contactName">Name <span className="required">*</span></label>
                           <input type="text" defaultValue="" size="35" id="contactName" name="contactName" onChange={this.handleChange} />
                        </div>

                        <div>
                           <label htmlFor="contactEmail">Email <span className="required">*</span></label>
                           <input type="text" defaultValue="" size="35" id="contactEmail" name="contactEmail" onChange={this.handleChange} />
                        </div>

                        <div>
                           <label htmlFor="contactSubject">Subject</label>
                           <input type="text" defaultValue="" size="35" id="contactSubject" name="contactSubject" onChange={this.handleChange} />
                        </div>

                        <div>
                           <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                           <textarea cols="50" rows="10" id="contactMessage" name="contactMessage" onChange={this.handleChange} ></textarea>
                        </div>

                        <div>
                           <Reaptcha size='normal' theme='dark' sitekey="6Lcbz54UAAAAAFSLFpH2fWlmS67emyF7zyw98JSs" onVerify={this.onVerify} />
                        </div>

                        <div>
                           <button className="submit" disabled={!this.validateForm() || !this.state.verified} onClick={this.handleSubmit}  >Submit</button>

                           {this.state.isLoading ?
                              <span id="image-loader">
                                 <img alt="" src="images/loader.gif" />
                              </span>
                              : ''}

                        </div>
                     </fieldset>
                  </form>

                  
                  {this.state.showError ?
                     <div id="message-warning">There was an error trying to send your message</div>
                     : ''
                  }

                  {this.state.showSuccess ?
                     <div id="message-success">
                        <i className="fa fa-check"></i>Your message was sent, thank you!<br />
                     </div>
                     : ''
                  }

               </div>


               <aside className="four columns footer-widgets">
                  <div className="widget widget_contact">

                     <h4>Gonzalo Rodriguez Mir</h4>
                     <p className="address">

                        Av. 32 #2882<br />
                        La Plata, Provincia de Buenos Aires, B1903AJQ
                        <br />
                        <span>Argentina</span><br />
                        <span><ObfuscateText text="grm@mobotsoft.com" /></span>

                     </p>
                  </div>

               </aside>
            </div>
         </section>
      );
   }
}

export default Contact;
