import React, { Component } from 'react';
import ObfuscateText from "react-obfuscate-text";

class CloudfarmingArticle extends Component {
    render() {

        return (

            <div className="row">
                <div className="twelve columns main-col">
                    <div className="article">
                        <h1>CloudFarming</h1>
                        <br/>
                        <p>
                            CloudFarming is a dynamic mission planner for agricultural robots or autonomous tractors. The system is based on a cloud computing platform and a modern web application as front-end.
                        </p>
                        <p>
                            <img src="/userguide/images/cloudfarming.png"></img>
                        </p>
                        <br/>
                        <p>
                            Features:
                        </p>
                        <p>
                            <ul>
                                <li>Route Planner with options for 'Orchard' and 'Open Field'</li>
                                <li>Generation of routes from imported orchard rows, changing parameters such as turning radius and row sequence pattern (straight alternation, skip and fill)</li>
                                <li>Calculation and display of statistics of planned routes (covered, overlap and uncovered areas, traveled and worked distance and time values, etc.)</li>
                                <li>Animation of the trajectory with a 3D model of the vehicle for easy visual tracking of the planned routes</li>
                                <li>Optimal division of complex shape fields into simpler subfields according to the working angle of each subfield</li>
                                <li>Connection to a ROS (Robot Operating System) network. Publication of messages with calculated routes and real-time display of sensors</li>
                                <li>Recording of routes in real time with the coordinates received from a GPS device. Simplification and smoothing of the curves obtained</li>
                                <li>Possibility to geometrically intersect recorded or imported routes with a user-defined working area to cut off turns and obtain orchard rows</li>
                                <li>Import and export of routes and fields in various formats</li>
                            </ul>
                        </p>
                        <p>
                            The videos on this page illustrate how CloudFarming works. For more details on using the latest version of the application, see the <a href="/userguide/index.html" target="_blank" rel="noopener noreferrer">CloudFarming User Guide</a>
                        </p>

                        <p>
                            <h4>Video 1: How to record a route in an orchard, extract the inter-row paths and generate new routes using the Route Planner</h4>
                        </p>
                        <p>
                            The video shows how to do it easily in a few steps:<br/>
                            1) Record a route in real-time from GPS measurements of a human-driven vehicle (or import a previously recorded route from a file in some format like Geojson or a txt with NMEA sentences)<br/>
                            2) Simplify and smooth the recorded route before saving it<br/>
                            3) Draw the working area to cut off the turns and extract the inter-row paths<br/>
                            4) Use the Route Planner to automatically generate the new turns that will connect the resulting paths. You can play with the parameters to generate routes for vehicles with different turning radius, or use different path sequence patterns
                        </p>
                        <p>
                        <video width="100%" controls poster="images/cloudfarmingarticle/cf-recording-routes-poster.png">
                        <source src="images/cloudfarmingarticle/cf-recording-routes.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                        </p>

                        <br/>
                        <p>
                            <h4>Video 2: Route Planner 'Open Field' option for the e-Robotiller</h4>
                        </p>
                        <p>
                            In the framework of the collaboration with the <a href="https://e-robotiller.nl/" target="_blank" rel="noopener noreferrer">e-Robotiller</a> project, a customized version of the Route Planner has been implemented. It creates a trajectory based on a Skip and Fill pattern for the mainland, and contour parallel swaths for the headland area. Concave and convex turns moving forward and backward have been implemented in these contour paths in order to achieve full coverage of the area.
                        </p>
                        <p>
                            These turns with forward and backward movements make it difficult to understand or visually follow the resulting route. For that reason CloudFarming features a 3D animation that allows us to analyze the robot's path through the planned route.
                        </p>
                        <p>
                            In this video you can see how a 3D model of the Robotiller follows the calculated route. It also shows the progress of the worked area as it moves. You can also see the covered, overlap and uncovered areas with their respective percentages of the total area.
                        </p>

                        <p>
                        <video width="100%" controls poster="images/cloudfarmingarticle/cf-areas-poster.png">
                        <source src="images/cloudfarmingarticle/cf-areas.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                        </p>

                        <br/>
                        <p>
                            <h4>Video 3: Using the 'SubFields' option of the Route Planner</h4>
                        </p>
                        <p>
                            The following video illustrates the ability of the Route Planner to divide a field into different regions. It shows how the user can create a farm and add one or more fields by drawing their boundaries on the map. The Route Planner then calculates the optimal route for each field based on its geometry and multiple parameters defined by the user.
                        </p>

                        <p>
                            Each field is conveniently divided into several subregions according to their shape, obtaining a different working angle for each subregion depending on whether you want to optimize working time or coverage area.
                        </p>

                        <p>
                        <video width="100%" controls poster="images/cloudfarmingarticle/cf-subfields-poster.png">
                        <source src="images/cloudfarmingarticle/cf-subfields.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                        </p>

                        <br/>
                        <p>
                            <h4>Video 4: Connecting CloudFarming to a ROS network</h4>
                        </p>
                        <p>
                        The following video demonstrates the integration of CloudFarming with sensors and actuators through ROS (Robot Operating System). In the example, a camera, an IMU sensor, and a laser range-finder are connected to the system:
                        </p>
                        <p>
                        <video width="100%" controls poster="images/cloudfarmingarticle/cf-ros-real-poster.png">
                        <source src="images/cloudfarmingarticle/cf-ros-real.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                        </p>

                        <br/>
                        <p>
                            <h4>Video 5: Publishing a ROS message containing a planned route to a route-following (simulated) robot</h4>
                        </p>
                        <p>
                        This last video shows how to use CloudFarming to create a Farm, run the Route Planner, and connect to a ROS network to send the calculated route to a robot and display the data received from the sensors. A simulated Husky UGV has been used in the new agriculture environment for Gazebo created by Clearpath Robotics.
                        </p>
                        <p>
                        <video width="100%" controls poster="images/cloudfarmingarticle/cf-ros-sim-poster.png">
                        <source src="images/cloudfarmingarticle/cf-ros-sim.mp4" type="video/mp4"/>
                        Your browser does not support the video tag.
                        </video>
                        </p>

                        <p>
                        The Route Planner can also be integrated into third party applications since it can be called as a web service.
                        </p>

                        <p>
                        The system is online for those who want to try it. If you are interested, please write me at: <ObfuscateText text="grm@mobotsoft.com"/> (or use the contact form) requesting the URL to obtain a username and password.
                        </p>

                        <p>
                        Your comments and proposals are welcome!
                        </p>

                        <p>
                        Technologies used:<br/>
                        Back-end: AWS serverless (AWS Lambda, Amazon API Gateway, Amazon DynamoDB)<br/>
                        Front-end: Javascript/ReactJS, ROS (robot operating system)
                        </p>

                    </div>

                </div>
            </div>


        );
    }
}

export default CloudfarmingArticle;